import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LandscapeIcon from "@mui/icons-material/Landscape";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";

export const SIDE_MENU = () => [
  // {
  //   label: "Dashboard",
  //   url: "/",
  //   value: "/",
  //   logo: <DashboardIcon sx={{ mr: 1 }} />,
  //   mapkey: 1,
  // },
  {
    label: "Media",
    url: "/media",
    value: "/media",
    logo: <LandscapeIcon sx={{ mr: 1 }} />,
    mapkey: 1,
  },
  {
    label: "Playlists",
    url: "/playlist",
    value: "/playlist",
    logo: <PlaylistPlayIcon sx={{ mr: 1 }} />,
    mapkey: 2,
  },
  {
    label: "Stores",
    url: "/stores",
    value: "/stores",
    logo: <StorefrontRoundedIcon sx={{ mr: 1 }} />,
    mapkey: 2,
  },
  {
    label: "Store Group",
    url: "/store-group",
    value: "/store-group",
    logo: <StorefrontRoundedIcon sx={{ mr: 1 }} />,
    mapkey: 2,
  },
  {
    label: "Specifications",
    url: "/specs",
    value: "/specs",
    logo: <SummarizeIcon sx={{ mr: 1 }} />,
    mapkey: 2,
  },
  // {
  //   label: "Integrations",
  //   url: "/integrations",
  //   value: "/integrations",
  //   logo: <IntegrationInstructionsIcon sx={{ mr: 1 }} />,
  //   mapkey: 2,
  // },
];
