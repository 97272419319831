import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../core/mui-table";
import { getHeaderConfig } from "./config";
import { Autocomplete, Button, Drawer, Grid, TextField } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddSpec from "./addSpec";
import CustomModal from "../../core/modal";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import SlidePreview from "../../core/slide-preview";

const Specifications = () => {
  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [isAddSpec, setIsAddSpec] = useState(false);
  const [preView, setPreview] = useState({
    isOpen: false,
    data: {},
  });
  const [editObj, setEditObj] = useState({});
  const [isDelete, setIsDelete] = useState({
    isOpen: false,
    data: {},
  });
  const [specificationList, setSpecificationList] = useState([]);
  const [searchSpec, setSearchSpec] = useState("");
  const clickHandler = (data, type) => {
    switch (type) {
      case "preview":
        setPreview({
          isOpen: true,
          data,
        });
        break;
      case "edit":
        setIsAddSpec(true);
        setEditObj(data);
        break;
      case "delete":
        setIsDelete({
          isOpen: true,
          data,
        });
        break;
      default:
        break;
    }
  };
  const deleteHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.SPECIFICATIONS}/${isDelete.data.id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
          setIsDelete({
            isOpen: false,
            data: {},
          });
        } else {
          toast.success("Specification Deleted Successfully", {
            autoClose: 2000,
          });
          setIsDelete({
            isOpen: false,
            data: {},
          });
          getSpec(filters);
        }
      })
      .catch((err) => console.error(err));
  };
  const getSpec = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.SPECIFICATIONS}`,
      null,
      filter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTableData(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const getSpecificationList = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_SPECIFICATIONS}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            label: ele.name,
            value: ele.name,
          }));
          setSpecificationList(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getSpecificationList(value);
    } else {
      setSearchSpec("");
    }
  };
  const searchHandler = () => {
    if (searchSpec) {
      getSpec({ ...filters, page: 1, name: searchSpec });
    } else {
      getSpec({ ...filters, page: 1 });
    }
  };
  useEffect(() => {
    getSpec(filters);
  }, []);
  return (
    <>
      {!isAddSpec && (
        <div className="spec-con">
          <h1 className="header">Specifications</h1>

          <Grid container md={12}>
            <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
              <Grid item md={3.5}>
                <Autocomplete
                  options={specificationList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Specification"
                      size="small"
                    />
                  )}
                  onInputChange={onInputChange}
                  onChange={(e, val) => {
                    if (val?.value) {
                      setSearchSpec(val.value);
                    } else {
                      setSearchSpec("");
                    }
                  }}
                />
              </Grid>
              <Grid item md={3.5}>
                <Button
                  variant="outlined"
                  size="small"
                  color="success"
                  startIcon={<SearchIcon />}
                  onClick={searchHandler}
                  sx={{ ml: 3 }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<AddOutlinedIcon />}
                onClick={() => setIsAddSpec(true)}
              >
                Add Specifications
              </Button>
            </Grid>
          </Grid>

          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData?.results || []}
            filters={filters}
            onClick={clickHandler}
            pageCount={tableData?.totalPages}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
      )}
      {isAddSpec && (
        <AddSpec
          setIsAddSpec={setIsAddSpec}
          getSpec={getSpec}
          editObj={editObj}
          setEditObj={setEditObj}
        />
      )}
      {preView?.isOpen && (
        <CustomModal onClose={() => setPreview({ isOpen: false, data: {} })}>
          <div className="spec-preview-main-con">
            {preView?.data?.screenDetails?.map((ele) => {
              return (
                // <Grid item md={4}>
                <div className="modal-preview-spec">
                  <SlidePreview mediaList={ele?.playlist?.mediaList} />
                </div>
                // </Grid>
              );
            })}
          </div>
        </CustomModal>
      )}
      {isDelete?.isOpen && (
        <CustomModal
          title="Delete Specification"
          onClose={() => {
            setIsDelete({
              isOpen: false,
              data: {},
            });
          }}
        >
          <h1>Are You Sure?</h1>
          <Button variant="contained" color="error" onClick={deleteHandler}>
            Delete
          </Button>
        </CustomModal>
      )}
    </>
  );
};

export default Specifications;
