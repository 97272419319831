import { Grid, TextField, Autocomplete, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, invokeApi, plainApi } from "../../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../../utils/endpoints";
const Stores = ({ setStoreGrpIds }) => {
  const [storeList, setStoreList] = useState([]);

  const getStoreGroups = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_STORE_GROUP}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setStoreList(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getStoreGroups(value);
    }
  };
  useEffect(() => {
    getStoreGroups();
  }, []);
  return (
    <div>
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <h1>Add Store Groups</h1>
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item md={6}>
          <Autocomplete
            options={storeList}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Store Group"
                size="small"
                required
                sx={{ mt: 3 }}
              />
            )}
            multiple
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val.length > 0) {
                const grpIds = val?.map((ele) => ele.value);
                setStoreGrpIds(grpIds);
              } else {
                setStoreGrpIds([]);
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Stores;
