import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../core/mui-table";
import { getHeaderConfig } from "./config";
import { Autocomplete, Button, Drawer, Grid, TextField } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddPlaylist from "./addPlaylist";
import CustomModal from "../../core/modal";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import SlidePreview from "../../core/slide-preview";

const Playlists = () => {
  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [editObj, setEditObj] = useState({});
  const [isAddPlaylist, setIsAddPlaylist] = useState(false);
  const [isDelete, setIsDelete] = useState({
    isOpen: false,
    data: {},
  });
  const [preView, setPreview] = useState({
    isOpen: false,
    data: {},
  });
  const [playlistList, setPlaylistList] = useState([]);
  const [searchPlaylist, setSearchPlaylist] = useState("");
  const clickHandler = (data, type) => {
    switch (type) {
      case "preview":
        setPreview({
          isOpen: true,
          data,
        });
        break;
      case "edit":
        setIsAddPlaylist(true);
        setEditObj(data);
        break;
      case "delete":
        setIsDelete({
          isOpen: true,
          data,
        });
        break;
      default:
        break;
    }
  };
  const getPlaylist = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.PLAYLIST}`,
      null,
      filter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const result = res.results.map((ele) => {
            let totalDuration = 0;
            if (ele.mediaList && ele.mediaList.length > 0) {
              ele.mediaList.forEach((media) => {
                if (media.duration) {
                  totalDuration += media.duration;
                }
              });
            }
            return { ...ele, totalDuration };
          });
          setTableData({ ...res, results: result });
        }
      })
      .catch((err) => console.error(err));
  };
  const deleteHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.PLAYLIST}/${isDelete.data.id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Playlist Deleted Successfully", { autoClose: 2000 });
          setIsDelete({
            isOpen: false,
            data: {},
          });
          getPlaylist(filters);
        }
      })
      .catch((err) => console.error(err));
  };
  const getPlaylistList = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_PLAYLIST}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            ...ele,
            label: ele.name,
            value: ele.name,
          }));
          setPlaylistList(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getPlaylistList(value);
    }
  };
  const searchHandler = () => {
    if (searchPlaylist) {
      getPlaylist({ ...filters, page: 1, name: searchPlaylist });
    } else {
      getPlaylist({ ...filters, page: 1 });
    }
  };
  useEffect(() => {
    getPlaylist(filters);
  }, []);
  return (
    <>
      {!isAddPlaylist && (
        <div className="playlist-con">
          <h1 className="header">Playlists</h1>
          <Grid container md={12} sx={{ mb: 2 }}>
            <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
              <Grid item md={3.5}>
                <Autocomplete
                  fullWidth
                  options={playlistList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Playlist"
                      size="small"
                      sx={{
                        mt: 2,
                        mb: 1.5,
                        ml: 0.5,
                        backgroundColor: "white",
                        width: "95%",
                      }}
                    />
                  )}
                  onInputChange={onInputChange}
                  onChange={(e, value) => {
                    if (value?.value) {
                      setSearchPlaylist(value.value);
                    } else {
                      setSearchPlaylist("");
                    }
                  }}
                />
              </Grid>
              <Grid item md={3.5}>
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<SearchIcon />}
                  onClick={searchHandler}
                  sx={{ ml: 3 }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<AddOutlinedIcon />}
                onClick={() => setIsAddPlaylist(true)}
              >
                Add Playlist
              </Button>
            </Grid>
          </Grid>

          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData?.results || []}
            filters={filters}
            onClick={clickHandler}
            pageCount={tableData?.totalPages}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
      )}

      {isAddPlaylist && (
        <AddPlaylist
          setIsAddPlaylist={setIsAddPlaylist}
          editObj={editObj}
          setEditObj={setEditObj}
          getPlaylist={getPlaylist}
        />
      )}
      {preView?.isOpen && (
        <CustomModal onClose={() => setPreview({ isOpen: false, data: {} })}>
          <div className="preview-con">
            <div className="playlist-preview-con">
              <SlidePreview mediaList={preView?.data?.mediaList} />
            </div>
          </div>
        </CustomModal>
      )}
      {isDelete?.isOpen && (
        <CustomModal
          title="Delete Media"
          onClose={() => {
            setIsDelete({
              isOpen: false,
              data: {},
            });
          }}
        >
          <h1>Are You Sure?</h1>
          <Button variant="contained" color="error" onClick={deleteHandler}>
            Delete
          </Button>
        </CustomModal>
      )}
    </>
  );
};

export default Playlists;
