const service = "/v1/dss-service/";
export const REST_URLS = {
  LOGIN: `${service}auth/login`,
  LOGOUT: `${service}auth/logout`,
  REFRESH_TOKEN: `${service}auth/refresh-tokens`,
  UPLOAD: `${service}media/upload`,
  MEDIA: `${service}media`,
  PLAYLIST: `${service}playlist`,
  STORE: `${service}store`,
  STORE_GROUP: `${service}store-group`,
  SPECIFICATIONS: `${service}specification`,
  SEARCH_STORE_GROUP: `${service}store-group/search-store-group`,
  SEARCH_SPECIFICATIONS: `${service}specification/search-specification`,
  SEARCH_PLAYLIST: `${service}playlist/search-playlist`,
  SEARCH_MEDIA: `${service}media/search-media`,
  SEARCH_STORE: `${service}store/search-store`,
};

// export const HOSTNAME = "http://localhost:4000";
export const HOSTNAME = "https://dev-api.mysubway.in"
