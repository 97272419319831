import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const getHeaderConfig = () => {
  return [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Media Count",
      id: "mediaCount",
    },
    {
      label: "Duration",
      id: "totalDuration",
    },
    {
      label: "Preview",
      id: "preview",
      render: (data, onClick) => {
        return (
          <Button
            size="small"
            varient="outlined"
            onClick={() => onClick(data, "preview")}
          >
            Preview
          </Button>
        );
      },
    },
    {
      label: "Actions",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <EditIcon
              color="info"
              onClick={() => onClick(data, "edit")}
              sx={{ cursor: "pointer" }}
            />
            <DeleteIcon
              color="error"
              onClick={() => onClick(data, "delete")}
              sx={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
