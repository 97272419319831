import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import "./index.scss";

import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SiFileUpload from "../../core/file-uploader";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { toast } from "react-toastify";
const AddMedia = ({ setIsAddMedia, editObj, setEditObj, getMedia }) => {
  const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const videoRef = useRef(null);
  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  const handleVideoMetadata = (e) => {
    const duration = e.target.duration;
    setFormData((prevVal) => ({ ...prevVal, duration: Math.round(duration) }));
  };
  const submitHandler = () => {
    if (isEdit) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.MEDIA}/${editObj?.id}`,
        formData
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Media Updated Successfully", { autoClose: 2000 });
            setIsAddMedia(false);
            setEditObj({});
            getMedia({ limit: 10, page: 1, sortBy: "-createdAt" });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.MEDIA}`, formData)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Media Created Successfully", { autoClose: 2000 });
            setIsAddMedia(false);
            getMedia({ limit: 10, page: 1, sortBy: "-createdAt" });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  useEffect(() => {
    if (editObj?.id) {
      setIsEdit(true);
      setFormData({
        name: editObj?.name,
        description: editObj?.description,
        mediaType: editObj?.mediaType,
        duration: editObj?.duration,
        mediaUrl: editObj?.mediaUrl,
      });
    }
  }, []);

  return (
    <div className="addMediaCon">
      <Grid md={12} container sx={{ display: "flex", alignItems: "flex-end" }}>
        <Grid
          item
          md={5}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            fontSize="large"
            sx={{ cursor: "pointer", mr: 2, ml: 3 }}
            onClick={() => {
              setIsAddMedia(false);
              setEditObj({});
              setIsEdit(false);
              getMedia({ page: 1, limit: 10, sortBy: "-createdAt" });
            }}
          />
          <p className="addMedia">{isEdit ? "Edit Media" : "Add Media"} </p>
        </Grid>
        <Grid item md={4}></Grid>
        <Grid item md={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="success"
            variant="outlined"
            size="small"
            startIcon={<SaveIcon />}
            onClick={submitHandler}
            disabled={
              !formData.name ||
              !formData.mediaType ||
              !formData.mediaUrl ||
              !formData.duration
            }
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Grid container md={12} sx={{ padding: 3 }} spacing={3}>
        <Grid item md={5} sx={{ display: "grid", gridGap: "25px" }}>
          <TextField
            label="Name"
            size="small"
            fullWidth
            onChange={formDataHandler}
            name="name"
            value={formData.name || ""}
            required
          />
          <TextField
            label="Description"
            size="small"
            fullWidth
            onChange={formDataHandler}
            name="description"
            value={formData.description || ""}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Type"
              value={formData?.mediaType || ""}
              size="small"
              name="mediaType"
              onChange={formDataHandler}
            >
              <MenuItem value="IMAGE">Image</MenuItem>
              <MenuItem value="VIDEO">Video</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Duration"
            size="small"
            fullWidth
            name="duration"
            required
            value={formData.duration || ""}
            onChange={formDataHandler}
          />
          <div>
            <p>Upload Image/Video*</p>
            <SiFileUpload
              title="Attachment"
              url={`${REST_URLS.UPLOAD}`}
              callBack={(newFile) => {
                if (newFile.data.url) {
                  if (
                    newFile.data.url.includes(".mp4") ||
                    newFile.data.url.includes(".webm")
                  ) {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      mediaUrl: newFile.data.url,
                      mediaType: "VIDEO",
                    }));
                  } else {
                    setFormData((prevVal) => ({
                      ...prevVal,
                      mediaUrl: newFile.data.url,
                      mediaType: "IMAGE",
                    }));
                  }
                }
              }}
              fileFormats={[
                "image/jpeg",
                "image/png",
                "image/jpg",
                "video/mp4",
                "video/webm",
              ]}
              accept=" video/*, image/*"
            />
            <p>Or</p>
            <TextField
              label="Enter Image/Video URL"
              size="small"
              fullWidth
              onChange={formDataHandler}
              name="mediaUrl"
              disabled={formData?.mediaUrl && !isEdit ? true : false}
              value={formData?.mediaUrl || ""}
            />
          </div>
        </Grid>
        <Grid
          item
          md={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {formData?.mediaUrl ? (
              <div className="previewCon white-bg">
                {formData.mediaUrl.includes(".mp4") ||
                formData.mediaUrl.includes(".webm") ? (
                  <video
                    ref={videoRef}
                    src={formData.mediaUrl}
                    controls
                    className="preview-video-size"
                    onLoadedMetadata={(e) => handleVideoMetadata(e)}
                  />
                ) : (
                  <img
                    src={formData.mediaUrl}
                    alt="Media Preview"
                    className="preview-img-size"
                  />
                )}
              </div>
            ) : (
              <div className="previewCon">
                <p>Preview</p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddMedia;
