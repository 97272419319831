import { Grid, Button, Box, Stepper, Step, StepLabel } from "@mui/material";
import "./index.scss";
import React, { useEffect, useState } from "react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import Details from "./addSpecComponents/details";
import Screens from "./addSpecComponents/screens";
import Stores from "./addSpecComponents/stores";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
const steps = ["Details", "Screens", "Stores"];
const AddSpec = ({ setIsAddSpec, getSpec, editObj, setEditObj }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [formData, setFormData] = useState({});
  const [specId, setSpecId] = useState("");
  const [storeGrpIds, setStoreGrpIds] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const createSpecification = () => {
    const payload = { ...formData };
    delete payload.noOfScreens;
    const filterScreenData = payload.screenDetails.map((item) => {
      if (
        item.screenId !== "" ||
        item.orientation !== "" ||
        item.playlist !== ""
      ) {
        if (item?.playlist?.id) {
          const updatedData = { ...item, playlist: item?.playlist?.id };
          return updatedData;
        } else {
          return item;
        }
      }
    });
    payload.screenDetails = filterScreenData;
    if (payload.screenDetails.length === 0) {
      toast.error("Empty Screens not allowed", { autoClose: 2000 });
      setActiveStep(1);
      return;
    }
    if (isEdit) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.SPECIFICATIONS}/${editObj.id}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
            setActiveStep(0);
          } else {
            toast.success("Specification updated successfully", {
              autoClose: 2000,
            });
            setSpecId(res?.id);
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.SPECIFICATIONS}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
            setActiveStep(0);
          } else {
            toast.success("Specification created successfully", {
              autoClose: 2000,
            });
            setSpecId(res?.id);
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const updateSpecInStoreGrp = ({ storeGrp, specId }) => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.STORE_GROUP}/${storeGrp}`,
      { specificationId: specId }
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setIsAddSpec(false);
          getSpec({
            limit: 10,
            page: 1,
            sortBy: "-createdAt",
          });
        }
      })
      .catch((err) => console.error(err));
  };
  const updateStoreGrpHandler = () => {
    if (storeGrpIds.length > 0 && specId) {
      storeGrpIds.forEach((storeGrp) => {
        updateSpecInStoreGrp({ storeGrp, specId });
      });
      toast.success("Specification linked with store group", {
        autoClose: 2000,
      });
    } else {
      toast.error("Store Group is required", { autoClose: 2000 });
    }
  };
  useEffect(() => {
    if (editObj?.id) {
      setIsEdit(true);
      setFormData({
        name: editObj?.name,
        description: editObj?.description,
        screenDetails: editObj?.screenDetails,
        noOfScreens: editObj?.screenDetails.length,
      });
    }
  }, []);
  useEffect(() => {
    if (
      activeStep === 2 &&
      formData.name &&
      formData.screenDetails.length > 0
    ) {
      createSpecification();
    }
    if (
      activeStep == 2 &&
      !formData.name &&
      formData.screenDetails.length === 0
    ) {
      toast.error("Please fill required information", { autoClose: 2000 });
      setActiveStep(0);
    }
  }, [activeStep]);
  return (
    <div className="addStoreCon">
      <Grid md={12} container sx={{ display: "flex", alignItems: "flex-end" }}>
        <Grid
          item
          md={4.5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            fontSize="large"
            sx={{ cursor: "pointer", mr: 2 }}
            onClick={() => {
              setIsAddSpec(false);
              setIsEdit(false);
              setEditObj({});
              getSpec({ page: 1, limit: 10, sortBy: "-createdAt" });
            }}
          />
          <p className="addMedia">
            {isEdit ? "Edit Specification" : "Add Specifications"}{" "}
          </p>
        </Grid>
      </Grid>
      <Box sx={{ width: "80%", mt: 5 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps} sx={{ mr: 5 }}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment></React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <Details setFormData={setFormData} formData={formData} />
            )}
            {activeStep === 1 && (
              <Screens
                setFormData={setFormData}
                formData={formData}
                isEdit={isEdit}
              />
            )}
            {activeStep === 2 && <Stores setStoreGrpIds={setStoreGrpIds} />}

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep !== steps.length - 1 && (
                <Button onClick={handleNext}>Next</Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button
                  onClick={updateStoreGrpHandler}
                  disabled={!specId || !storeGrpIds}
                  color="success"
                >
                  Save
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
};

export default AddSpec;
