import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import { Grid, TextField, Button } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import SlidePreview from "../../core/slide-preview";
import MediaSelector from "./mediaCard";
import { DragDropContext } from "react-beautiful-dnd";
const AddPlaylist = ({
  setIsAddPlaylist,
  editObj,
  setEditObj,
  getPlaylist,
}) => {
  const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [allMedia, setAllMedia] = useState([]);
  const [activeMedia, setActiveMedia] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const submitHandler = () => {
    const mediaList = activeMedia.map((ele) => ele.id);
    const body = { ...formData, mediaList };
    if (isEdit) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.PLAYLIST}/${editObj?.id}`,
        body
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Media Updated Successfully", { autoClose: 2000 });
            setIsAddPlaylist(false);
            setEditObj({});
            getPlaylist({ limit: 10, page: 1, sortBy: "-createdAt" });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.PLAYLIST}`, body)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Playlist Created Successfully", { autoClose: 2000 });
            setIsAddPlaylist(false);
            getPlaylist({ limit: 10, page: 1, sortBy: "-createdAt" });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const getMedia = (params) => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MEDIA}`, null, params)
      .then((response) => {
        if (response) {
          const activeMediaIds = activeMedia.map((media) => media.id);
          const newMediaItems = response.results.filter(
            (media) => !activeMediaIds.includes(media.id)
          );
          setAllMedia(newMediaItems);
        } else {
          console.error("Fetching Media failed");
        }
      })
      .catch((err) => {
        console.error("Fetching Media failed");
      });
  };

  const onDragEnd = (result) => {
    setRefresh(Math.random());
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let add;
    let allMediaList = allMedia;
    let activeMediaList = activeMedia;
    // Source Logic
    if (source.droppableId === "ALL_MEDIA") {
      add = allMediaList[source.index];
      allMediaList.splice(source.index, 1);
    } else {
      add = activeMediaList[source.index];
      activeMediaList.splice(source.index, 1);
    }

    // Destination Logic
    if (destination.droppableId === "ALL_MEDIA") {
      allMediaList.splice(destination.index, 0, add);
    } else {
      activeMediaList.splice(destination.index, 0, add);
    }

    setActiveMedia(activeMediaList);
    setAllMedia(allMediaList);
  };

  useEffect(() => {
    if (editObj?.id) {
      setIsEdit(true);
      setFormData({
        name: editObj?.name,
        description: editObj?.description,
      });
      setActiveMedia(editObj?.mediaList);
    }
  }, []);
  useEffect(() => {
    getMedia({ page: 1, limit: 50, sortBy: "-createdAt" });
  }, []);
  useEffect(() => {
    if (isEdit && allMedia?.length > 0) {
      const filteredMedia = allMedia.filter(
        (ele) => !editObj.mediaList?.some((media) => media.id === ele.id)
      );
      // Update state only if it's different from the current state
      if (JSON.stringify(filteredMedia) !== JSON.stringify(allMedia)) {
        setAllMedia(filteredMedia);
      }
      setShouldRenderComponent(true);
    }
  }, [editObj, allMedia]);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // After 2 seconds, set shouldRenderComponent to true
  //     setShouldRenderComponent(true);
  //   }, 2000);

  //   // Clear the timer if the component is unmounted before the 2 seconds
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <div className="addPlaylistCon">
      <Grid md={12} container sx={{ display: "flex", alignItems: "center" }}>
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            ml: 3,
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            fontSize="large"
            sx={{ cursor: "pointer", mr: 2 }}
            onClick={() => {
              setIsAddPlaylist(false);
              setEditObj({});
            }}
          />
          <p className="addPlaylist">
            {isEdit ? "Edit Playlist" : "Add Playlist"}{" "}
          </p>
        </Grid>
        <Grid item md={5.5}></Grid>
        <Grid
          item
          md={2}
          sx={{ display: "flex", justifyContent: "space-around" }}
        >
          <Button
            color="success"
            variant="outlined"
            size="small"
            startIcon={<SaveIcon />}
            onClick={submitHandler}
            disabled={
              !formData.name ||
              !formData.description ||
              activeMedia.length === 0
            }
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ padding: 3, pb: 0, display: "flex", justifyContent: "center" }}
        spacing={3}
      >
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            label="Name"
            size="small"
            fullWidth
            name="name"
            onChange={formDataHandler}
            required
            value={formData.name || ""}
          />
          <TextField
            label="Description"
            size="small"
            fullWidth
            sx={{ mt: 3 }}
            name="description"
            onChange={formDataHandler}
            required
            value={formData.description || ""}
          />
          <div>
            {activeMedia.length > 0 ? (
              <div className="playlistPreviewCon bg-white">
                <div className="preview-img-size">
                  <SlidePreview mediaList={activeMedia} />
                </div>
              </div>
            ) : (
              <div className="playlistPreviewCon">
                <p>Preview</p>
              </div>
            )}
          </div>
        </Grid>
        {isEdit ? (
          shouldRenderComponent && (
            <Grid
              item
              md={8}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <MediaSelector
                  allMedia={allMedia}
                  activeMedia={activeMedia}
                  getMedia={getMedia}
                />
              </DragDropContext>
            </Grid>
          )
        ) : (
          <Grid
            item
            md={8}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <MediaSelector
                allMedia={allMedia}
                activeMedia={activeMedia}
                getMedia={getMedia}
              />
            </DragDropContext>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AddPlaylist;
