import React, { useState } from "react";
import "./index.scss";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import PhotoIcon from "@mui/icons-material/Photo";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { Autocomplete } from "@mui/material";

const MediaSelector = ({ allMedia, activeMedia, getMedia }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [mediaList, setMediaList] = useState([]);
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      searchMediaList(value, "all");
    }
  };

  const searchMediaList = (value, type) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_MEDIA}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            label: ele.name,
            value: ele.name,
          }));
          if (type === "all") {
            setMediaList(result);
          }
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const filteredActiveMedia = activeMedia.filter((media) =>
    media.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Grid container md={12}>
      {/* Active Media Section */}
      <Grid
        item
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p className="media-header">Active Media</p>
        <TextField
          placeholder="Search"
          size="small"
          value={searchQuery}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ cursor: "pointer" }} />
              </InputAdornment>
            ),
            sx: { borderRadius: "20px", mb: 2, width: "22rem" },
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Droppable droppableId="ACTIVE_MEDIA">
          {(provided) => (
            <>
              <div
                ref={provided.innerRef}
                {...provided.dropableProps}
                className="media-card-con"
              >
                {filteredActiveMedia?.map((media, index) => (
                  <Draggable
                    draggableId={media?.id.toString()}
                    index={index}
                    key={media?.id.toString()}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="media-card"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <div>
                          {media?.mediaType === "VIDEO" ? (
                            <MovieFilterIcon />
                          ) : (
                            <img
                              src={media.mediaUrl}
                              style={{ width: "30px", height: "30px" }}
                            />
                          )}
                        </div>
                        <div>
                          {media?.name}
                          <div style={{ color: "green" }}>
                            {media?.mediaType}- {media?.description}
                          </div>
                        </div>
                        <div>{media?.duration}</div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </>
          )}
        </Droppable>
      </Grid>

      {/* All Media Section */}
      <Grid
        item
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p className="media-header" style={{ marginLeft: "8rem" }}>
          All Media
        </p>
        <Autocomplete
          options={mediaList}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              size="small"
              sx={{
                mb: 2,
                width: "22rem",
              }}
            />
          )}
          onInputChange={onInputChange}
          onChange={(e, val) => {
            if (val?.value) {
              getMedia({
                limit: 100,
                page: 1,
                sortBy: "-createdAt",
                name: val.value,
              });
            } else {
              getMedia({ limit: 100, page: 1, sortBy: "-createdAt" });
            }
          }}
        />

        <Droppable droppableId="ALL_MEDIA">
          {(provided) => (
            <div
              className="media-card-con "
              ref={provided.innerRef}
              {...provided.dropableProps}
            >
              {allMedia?.map((media, index) => (
                <Draggable
                  draggableId={media?.id.toString()}
                  index={index}
                  key={media?.id.toString()}
                >
                  {(provided, snapshot) => (
                    <div
                      className="media-card"
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <div>
                        {media?.mediaType === "VIDEO" ? (
                          <MovieFilterIcon />
                        ) : (
                          <img
                            src={media.mediaUrl}
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </div>
                      <div>
                        {media?.name}
                        <div style={{ color: "green" }}>
                          {media?.mediaType}- {media?.description}
                        </div>
                      </div>
                      <div>{media?.duration}</div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Grid>
    </Grid>
  );
};

export default MediaSelector;
