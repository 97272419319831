import { Grid, TextField } from "@mui/material";
import React from "react";

const Details = ({ setFormData, formData }) => {
  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  return (
    <div>
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "center", mt: 7 }}
      >
        <Grid item md={5}>
          <TextField
            label="Name"
            fullWidth
            size="small"
            name="name"
            required
            onChange={formDataHandler}
            value={formData?.name}
          />
          <TextField
            label="Description"
            fullWidth
            size="small"
            sx={{ mt: 3, mb: 3 }}
            name="description"
            onChange={formDataHandler}
            value={formData?.description}
          />
          <TextField
            label="No. of screens"
            fullWidth
            size="small"
            name="noOfScreens"
            onChange={formDataHandler}
            value={formData?.noOfScreens}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Details;
