import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import "./index.scss";
import { clearOfflineData, getOfflineData } from "../../utils/offline-services";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import SideMenu from "../side-menu";
const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const path = window.location.pathname;
  useEffect(() => {
    let user = getOfflineData("user");
    setUserDetails(user);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    // const refreshToken = getOfflineData("tokens")?.refresh?.token || "";
    // invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.LOGOUT}`, {
    //   refreshToken,
    // })
    //   .then(() => {
    //     clearOfflineData("user");
    //     navigate("/login");
    //   })
    //   .catch((err) => {
    //     clearOfflineData("user");
    //     navigate("/login");
    //   });
  };
  return (
    <div className="header-container">
      <div className="r-c">
        {/* <img
          src={require("../../resources/images/logo.png")}
          className="logo"
        /> */}
        {/* <SideMenu /> */}
        <p>{path === "/" && "Dashboard"}</p>
      </div>

      <div className="menu">
        <Button
          size="small"
          variant="text"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {userDetails.name}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={logout} className="logout-menu">
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
