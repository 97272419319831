import React, { useState, useEffect } from "react";
import "./index.scss";
const SlidePreview = ({ mediaList = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (mediaList.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaList.length);
      }, mediaList[currentIndex].duration * 1000);

      return () => clearInterval(interval);
    }
  }, [currentIndex, mediaList]);

  return (
    <div className="video-size">
      {mediaList?.map((media, index) => (
        <div
          key={media.id}
          style={{ display: index === currentIndex ? "block" : "none" }}
        >
          {media.mediaType === "VIDEO" ? (
            <video
              autoPlay
              muted
              loop
              playsInline
              src={media.mediaUrl}
              className="video-size"
            />
          ) : (
            <img src={media.mediaUrl} alt={media.name} className="video-size" />
          )}
        </div>
      ))}
    </div>
  );
};

export default SlidePreview;
