import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import Particles from "react-tsparticles";
import { useNavigate } from "react-router-dom/dist";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { getOfflineData, setOfflineData } from "../../utils/offline-services";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { loadFull } from "tsparticles";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";

export const Login = () => {
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const changeHandler = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setLoginDetails((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };
  const loginFormSubmitHandler = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.LOGIN}`,
      loginDetails
    ).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        res.user && setOfflineData("user", res.user);
        res.tokens && setOfflineData("tokens", res.tokens);
        navigate("/");
      }
    });
  };
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  useEffect(() => {
    const isUser = getOfflineData("user");
    if (isUser) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <div className="login-container">
        <Particles
          id="tsparticles"
          init={particlesInit}
          // loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#F6F6F6",
              },
            },
            fpsLimit: 30,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#1E266D",
              },
              links: {
                color: "#1E266D",
                distance: 150,
                enable: true,
                opacity: 1,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 5,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 1000,
                },
                value: 40,
              },
              opacity: {
                value: 1,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
        <div className="right">
          <div className="login-form">
            <img
              src={require("../../resources/images/logo.png")}
              className="img"
            />
            <div className="title">Login</div>

            <TextField
              label="Email"
              size="small"
              name="email"
              required
              onChange={changeHandler}
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              size="small"
              name="password"
              required
              onChange={changeHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              fullWidth
              className="loginButton"
              onClick={loginFormSubmitHandler}
              disabled={
                !loginDetails.email || !loginDetails.password ? true : false
              }
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
