import { Button, Menu, MenuItem } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import StayCurrentLandscapeIcon from "@mui/icons-material/StayCurrentLandscape";
export const getHeaderConfig = () => {
  return [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Screens",
      id: "screens",
      render: (data) => {
        return (
          <div>
            {data?.verScreenCount > 0 && data?.horScreenCount ? (
              <span
                style={{
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StayCurrentPortraitIcon /> * {data?.verScreenCount} ,{"     "}
                <StayCurrentLandscapeIcon /> * {data?.horScreenCount}
              </span>
            ) : data?.verScreenCount > 0 ? (
              <span
                style={{
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StayCurrentPortraitIcon /> * {data?.verScreenCount}
              </span>
            ) : data?.horScreenCount > 0 ? (
              <span
                style={{
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StayCurrentLandscapeIcon /> * {data?.horScreenCount}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      label: "Preview",
      id: "preview",
      render: (data, onClick) => {
        return (
          <Button
            size="small"
            varient="outlined"
            onClick={() => onClick(data, "preview")}
          >
            Preview
          </Button>
        );
      },
    },
    {
      label: "Actions",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <EditIcon
              color="info"
              onClick={() => onClick(data, "edit")}
              sx={{ cursor: "pointer" }}
            />
            <DeleteIcon
              color="error"
              onClick={() => onClick(data, "delete")}
              sx={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
