import React from "react";
import "./index.scss";
import Header from "../core/header";
import SideMenu from "../core/side-menu";
import { Grid } from "@mui/material";

const MainContainer = ({ children }) => {
  return (
    // <div className="main-container">
    //   <div>
    //     <SideMenu />
    //   </div>
    //   <Header />
    //   <div className="main-right-container">
    //     <div className="content-div">
    //       <div>{children}</div>
    //     </div>
    //   </div>
    // </div>
    <div>
      <Grid container md={12}>
        <Grid item md={2} sm={3}>
          <SideMenu />
        </Grid>
        <Grid item md={10} sx={{ height: "100vh", overflow: "scroll" }} sm={9}>
          {/* <Header /> */}
          <div className="main-right-container">
            <div className="content-div">
              <div>{children}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainContainer;
