import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../core/mui-table";
import { getHeaderConfig } from "./config";
import { Autocomplete, Button, Drawer, Grid, TextField } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddStoreGroup from "./addStoreGroup";
import CustomModal from "../../core/modal";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";

const StoreGroup = () => {
  const [tableData, setTableData] = useState({});
  const [editObj, setEditObj] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [isDelete, setIsDelete] = useState({
    isOpen: false,
    data: {},
  });
  const [storeList, setStoreList] = useState([]);
  const [searchStoreGroup, setSearchStoreGroup] = useState("");
  const [isAddStoreGroup, setIsAddStoreGroup] = useState(false);
  const clickHandler = (data, type) => {
    switch (type) {
      case "edit":
        setIsAddStoreGroup(true);
        setEditObj(data);
        break;
      case "delete":
        setIsDelete({
          isOpen: true,
          data,
        });
        break;
      default:
        break;
    }
  };
  const deleteHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.STORE_GROUP}/${isDelete.data.id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
          setIsDelete({
            isOpen: false,
            data: {},
          });
        } else {
          toast.success("Store Group Deleted Successfully", {
            autoClose: 2000,
          });
          setIsDelete({
            isOpen: false,
            data: {},
          });
          getStoreGroups(filters);
        }
      })
      .catch((err) => console.error(err));
  };
  const getStoreGroups = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.STORE_GROUP}`,
      null,
      filter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTableData(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const getStoreGroupList = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_STORE_GROUP}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            label: ele.name,
            value: ele.name,
          }));
          setStoreList(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getStoreGroupList(value);
    } else {
      setSearchStoreGroup("");
    }
  };
  const searchHandler = () => {
    if (searchStoreGroup) {
      getStoreGroups({ ...filters, page: 1, name: searchStoreGroup });
    } else {
      getStoreGroups({ ...filters, page: 1 });
    }
  };
  useEffect(() => {
    getStoreGroups();
  }, []);
  return (
    <>
      {!isAddStoreGroup && (
        <div className="media-con">
          <h1 className="header">Store Groups</h1>
          <Grid container md={12} sx={{ mb: 2 }}>
            <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
              <Grid item md={3.5}>
                <Autocomplete
                  options={storeList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Store Group"
                      size="small"
                    />
                  )}
                  onInputChange={onInputChange}
                  onChange={(e, val) => {
                    if (val?.value) {
                      setSearchStoreGroup(val.value);
                    } else {
                      setSearchStoreGroup("");
                    }
                  }}
                />
              </Grid>
              <Grid item md={3.5}>
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<SearchIcon />}
                  onClick={searchHandler}
                  sx={{ ml: 3 }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<AddOutlinedIcon />}
                onClick={() => setIsAddStoreGroup(true)}
              >
                Add Store Group
              </Button>
            </Grid>
          </Grid>

          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData?.results || []}
            filters={filters}
            onClick={clickHandler}
            pageCount={tableData?.totalPages}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
      )}
      {isAddStoreGroup && (
        <AddStoreGroup
          setIsAddStoreGroup={setIsAddStoreGroup}
          getStoreGroups={getStoreGroups}
          editObj={editObj}
          setEditObj={setEditObj}
        />
      )}
      {isDelete?.isOpen && (
        <CustomModal
          title="Delete Group Store"
          onClose={() => {
            setIsDelete({
              isOpen: false,
              data: {},
            });
          }}
        >
          <h1>Are You Sure?</h1>
          <Button variant="contained" color="error" onClick={deleteHandler}>
            Delete
          </Button>
        </CustomModal>
      )}
    </>
  );
};

export default StoreGroup;
