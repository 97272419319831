import { Button, Menu, MenuItem } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const getHeaderConfig = () => {
  return [
    {
      label: "Store Group",
      id: "storeGroupId.name",
      render: (data) => {
        return data?.storeGroupId?.name;
      },
    },
    {
      label: "Store Code",
      id: "storeCode",
    },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Description",
      id: "description",
    },
    {
      label: "Area",
      id: "area",
    },
    {
      label: "City",
      id: "city",
    },
    // {
    //   label: "Preview",
    //   id: "preview",
    //   render: (data, onClick) => {
    //     return (
    //       <Button size="small" varient="outlined" onClick={() => onClick(data)}>
    //         Preview
    //       </Button>
    //     );
    //   },
    // },
    {
      label: "Actions",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <EditIcon
              color="info"
              onClick={() => onClick(data, "edit")}
              sx={{ cursor: "pointer" }}
            />
            <DeleteIcon
              color="error"
              onClick={() => onClick(data, "delete")}
              sx={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
