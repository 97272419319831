import { Button } from "@mui/material";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const getHeaderConfig = () => {
  return [
    {
      label: "Media ",
      id: "mediaLogo",
      render: (data) =>
        data?.mediaType === "IMAGE" ? (
          <img src={data.mediaUrl} style={{ width: "30px", height: "30px" }} />
        ) : (
          <MovieFilterIcon fontSize="large" />
        ),

      // if (data.mediaLogo === "img") {
      //   return <PhotoIcon />;
      // } else {
      //   return <MovieFilterIcon />;
      // }
    },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Type",
      id: "mediaType",
    },
    {
      label: "Duration",
      id: "duration",
    },
    {
      label: "Preview",
      id: "preview",
      render: (data, onClick) => {
        return (
          <Button
            size="small"
            varient="outlined"
            onClick={() => onClick(data, "preview")}
          >
            Preview
          </Button>
        );
      },
    },
    {
      label: "Actions",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <EditIcon
              color="info"
              onClick={() => onClick(data, "edit")}
              sx={{ cursor: "pointer" }}
            />
            <DeleteIcon
              color="error"
              onClick={() => onClick(data, "delete")}
              sx={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
