import { Button, Menu, MenuItem } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const getHeaderConfig = () => {
  return [
    {
      label: "Group Code",
      id: "groupCode",
    },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Description",
      id: "description",
    },
    {
      label: "Specification",
      id: "spec",
      render: (data) => {
        return data?.specificationId?.name;
      },
    },
    {
      label: "Actions",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <EditIcon
              color="info"
              onClick={() => onClick(data, "edit")}
              sx={{ cursor: "pointer" }}
            />
            <DeleteIcon
              color="error"
              onClick={() => onClick(data, "delete")}
              sx={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
