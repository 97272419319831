import React, { useState } from "react";
import "./index.scss";
import AddIntegration from "./addIntegration";
import { Grid } from "@mui/material";
import restogroLogo from "../../resources/images/logo.png";
const Integrations = () => {
  const [isCreateIntegration, setIsCreateIntegration] = useState(false);
  const [activePos, setActivePos] = useState("Restogro");
  const [posList, setPosList] = useState([
    { id: 1, name: "Restogro", img: restogroLogo },
    { id: 2, name: "POS 1" },
    { id: 3, name: "POS 2" },
  ]);
  return (
    <div className="integration-con">
      <>
        <h1 className="header">POS Integrations</h1>
        <Grid md={12} container>
          {posList.map((pos) => {
            let isActive = false;
            if (pos.name === activePos) {
              isActive = true;
            } else {
              isActive = false;
            }
            return (
              <Grid
                item
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className={isActive ? "card-con active-card " : "card-con"}
                  onClick={() => setActivePos(pos.name)}
                >
                  {pos.img ? (
                    <img src={require("../../resources/images/logo.png")} />
                  ) : (
                    <h1>{pos.name}</h1>
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>
        {activePos === "Restogro" ? (
          <AddIntegration setIsCreateIntegration={setIsCreateIntegration} />
        ) : (
          <h3>No POS Integration Available</h3>
        )}
      </>
    </div>
  );
};

export default Integrations;
