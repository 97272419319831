/** @format */

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import "./index.scss";
import { Button, Menu, MenuItem, ListItemIcon } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { SIDE_MENU } from "./config";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { clearOfflineData, getOfflineData } from "../../utils/offline-services";
export default function SideMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState("/");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const path = window.location.pathname;
  React.useEffect(() => {
    setActiveMenu(
      SIDE_MENU().find((m) => m.url === window.location.pathname) || {}
    );
  }, [SIDE_MENU, window.location.pathname]);
  const logout = () => {
    const refreshToken = getOfflineData("tokens")?.refresh?.token || "";
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.LOGOUT}`, {
      refreshToken,
    })
      .then(() => {
        clearOfflineData("user");
        navigate("/login");
      })
      .catch((err) => {
        clearOfflineData("user");
        navigate("/login");
      });
  };
  const onMenuClick = () => {};
  const handleClose = () => {
    setAnchorEl(null);
  };
  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={() => setOpen(!open)}
      onKeyDown={() => setOpen(!open)}
    >
      <div className="side-menu-header">
        <img
          src={require("../../resources/images/logo.png")}
          className="logo-img"
        />
        {/* <IconButton onClick={() => setOpen(!open)}>
            <ArrowBackIosNewIcon />
          </IconButton> */}
      </div>
      <List>
        <div className="menu-items">
          {SIDE_MENU()?.map((menu, index) => {
            let showMenuOption = true;
            return (
              <>
                {
                  <div
                    key={menu.mapkey}
                    className={
                      menu.value === activeMenu.value
                        ? "menu-item menu-item-active"
                        : "menu-item"
                    }
                    onClick={() => {
                      onMenuClick();
                      setActiveMenu(menu);
                      navigate(menu.url);
                      if (menu.url === activeMenu?.url) {
                        window.location.reload();
                      }
                    }}
                  >
                    <div className="menu-name align-center">
                      <span>{menu.logo}</span>
                      <span className="sidebarmenulabel">{menu.label}</span>
                    </div>
                  </div>
                }
              </>
            );
          })}
        </div>
      </List>
      <div className="menu">
        <Button
          size="small"
          variant="text"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowUpIcon />}
          sx={{ color: "white", ml: 10 }}
        >
          {userDetails.name}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={logout} className="logout-menu">
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
  useEffect(() => {
    let user = getOfflineData("user");
    setUserDetails(user);
  }, []);
  return (
    <div className="side-menu-con">
      {/* <IconButton onClick={() => setOpen(!open)}>
        {open ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton> */}
      {/* <Drawer anchor="left" open={open} onClose={() => setOpen(false)}> */}
      {list("left")}
      {/* </Drawer> */}
    </div>
  );
}
