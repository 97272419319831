import React, { useState, useEffect } from "react";
import "./index.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Pagination } from "@mui/material";

const MuiTable = ({
  dataList,
  columnsList,
  pageCount,
  onChange,
  filters,
  onClick,
  maxWords,
  customSiRowClass = "",
  showAllFunc,
  customTableClass,
  customTableConClass,
  nestedValue,
}) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (Number.isInteger(parseInt(filters?.page))) {
      setPage(filters.page);
    }
  }, [filters]);
  function truncateWords(text, maxWords) {
    const words = text?.split(" ");
    if (words.length <= maxWords) {
      return text;
    }
    return words.slice(0, maxWords).join(" ") + "...";
  }

  return (
    <div
      className={`custom-mui-table ${customTableClass ? customTableClass : ""}`}
    >
      <div
        className={`mui-table-with-scroll ${
          customTableConClass ? customTableConClass : ""
        }`}
      >
        <div className={` con-scroll`}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead stickyHeader>
              <TableRow>
                {columnsList?.map((head) => (
                  <TableCell
                    className={`table-con-cell ${customSiRowClass}`}
                    key={head.id}
                    align="center"
                    padding="none"
                    role="checkbox"
                  >
                    {head.headerRender ? (
                      head.headerRender()
                    ) : (
                      <>{head.label}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList?.map((ele, index) => (
                <TableRow
                  key={ele.id ? ele.id : index}
                  className="mui-table-row"
                >
                  {columnsList?.map((col, index) => {
                    let value = ele[col.id];
                    if (typeof col?.valueConversionLogic === "function") {
                      value = col.valueConversionLogic(value);
                    }
                    if (col.render) {
                      return (
                        <TableCell
                          key={col.id ? col.id : index}
                          align="center"
                          className={`body-table-cell ${customSiRowClass}`}
                        >
                          {col.render(ele, onClick, index)}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={col.id ? col.id : index}
                        align="center"
                        className={`body-table-cell ${customSiRowClass}`}
                      >
                        {maxWords &&
                        `${value}`?.split(" ").length > maxWords ? (
                          <p className="read-more-text">
                            {truncateWords(value, maxWords)}{" "}
                            <span
                              className="show-more"
                              onClick={() =>
                                showAllFunc({ isOpen: true, text: value })
                              }
                            >
                              Read More
                            </span>
                          </p>
                        ) : (
                          value
                        )}
                        {/* {value} */}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      <div className="si-pagination">
        {pageCount > 1 && (
          <Pagination
            page={page}
            count={pageCount}
            className="custom-pagination"
            color="primary"
            onChange={(e, page) => {
              setPage(page);
              if (onChange) {
                onChange(page);
              }
            }}
          ></Pagination>
        )}
      </div>
    </div>
  );
};

export default MuiTable;
