import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Autocomplete } from "@mui/material";
import "./index.scss";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";

const AddStores = ({ setIsAddStore, getStores, editObj, setEditObj }) => {
  const [storeGroups, setStoreGroups] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const formDataHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  const getStoreGroupList = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_STORE_GROUP}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setStoreGroups(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const formSubmitHandler = () => {
    const payload = {};
    for (let i in formData) {
      if (formData[i]) {
        payload[i] = formData[i];
      }
    }
    if (isEdit) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.STORE}/${editObj?.id}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Store Updated Successfully", { autoClose: 2000 });
            setIsAddStore(false);
            setEditObj({});
            getStores({ limit: 10, page: 1, sortBy: "-createdAt" });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.STORE}`,
        payload
      ).then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Store created successfully", { autoClose: 2000 });
          setIsAddStore(false);
          getStores({
            limit: 10,
            page: 1,
            sortBy: "-createdAt",
          });
        }
      });
    }
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getStoreGroupList(value);
    } else {
      setFormData((prevVal) => ({ ...prevVal, storeGroupId: "" }));
    }
  };
  useEffect(() => {
    if (editObj?.id) {
      setIsEdit(true);
      setFormData({
        name: editObj?.name,
        description: editObj?.description,
        city: editObj?.city,
        storeType: editObj?.storeType,
        area: editObj?.area,
        storeCode: editObj?.storeCode,
        storeGroupId: editObj?.storeGroupId?.id,
      });
    }
  }, []);

  return (
    <div className="addStoreCon">
      <Grid md={12} container sx={{ display: "flex", alignItems: "flex-end" }}>
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            fontSize="large"
            sx={{ cursor: "pointer", mr: 2 }}
            onClick={() => setIsAddStore(false)}
          />
          <p className="addMedia">{isEdit ? "Edit Store" : "Add Store"} </p>
        </Grid>
        <Grid item md={4.8}></Grid>
        <Grid item md={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="success"
            variant="outlined"
            size="small"
            startIcon={<SaveIcon />}
            disabled={
              !formData.name || !formData.storeGroupId || !formData.storeCode
            }
            onClick={formSubmitHandler}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ padding: 3, display: "flex", justifyContent: "center" }}
        spacing={3}
      >
        <Grid item md={5} sx={{ display: "grid", gridGap: "20px" }}>
          <TextField
            label="Name"
            size="small"
            fullWidth
            name="name"
            required
            value={formData?.name}
            onChange={formDataHandler}
          />
          <TextField
            label="Description"
            size="small"
            fullWidth
            name="description"
            value={formData?.description}
            onChange={formDataHandler}
          />
          <TextField
            label="City"
            size="small"
            fullWidth
            name="city"
            value={formData?.city}
            onChange={formDataHandler}
          />
          <TextField
            label="Store Type"
            size="small"
            fullWidth
            name="storeType"
            value={formData?.storeType}
            onChange={formDataHandler}
          />
        </Grid>
        <Grid item md={5}>
          <TextField
            label="Area"
            size="small"
            fullWidth
            name="area"
            value={formData?.area}
            onChange={formDataHandler}
          />
          <TextField
            label="Store Code"
            size="small"
            fullWidth
            required
            name="storeCode"
            value={formData?.storeCode}
            sx={{ mb: 2.5, mt: 2.5 }}
            onChange={formDataHandler}
          />
          <Autocomplete
            options={storeGroups}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selct Store Group"
                size="small"
                required
              />
            )}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val?.value) {
                setFormData((prevVal) => ({
                  ...prevVal,
                  storeGroupId: val.value,
                }));
              } else {
                setFormData((prevVal) => ({ ...prevVal, storeGroupId: "" }));
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddStores;
