import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import "./index.scss";
import SaveIcon from "@mui/icons-material/Save";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
const AddIntegration = ({ setIsCreateIntegration }) => {
  const [file, setFile] = useState(null); // State to store the selected file
  const [previewURL, setPreviewURL] = useState(""); // State to store the preview URL
  const [playlistList, setPlaylistList] = useState([
    { label: "Playlist 1", value: "playlist1" },
    { label: "Playlist 2", value: "playlist2" },
    { label: "Playlist 3", value: "playlist3" },
    { label: "Playlist 4", value: "playlist4" },
  ]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemList, setItemList] = useState([
    { id: 1, name: "Sandwich", desc: "Veg Sandwich", price: 100 },
    { id: 2, name: "Burger", desc: "Peri Peri Burger", price: 150 },
    { id: 3, name: "Fries", desc: "Cheesy Fries", price: 80 },
    { id: 4, name: "Pizza", desc: "Margherita Pizza", price: 200 },
    { id: 5, name: "Salad", desc: "Caesar Salad", price: 120 },
    { id: 6, name: "Pasta", desc: "Spaghetti Carbonara", price: 180 },
    { id: 7, name: "Sushi", desc: "California Roll", price: 220 },
    { id: 8, name: "Chicken Wings", desc: "Buffalo Wings", price: 120 },
    { id: 9, name: "Pancakes", desc: "Blueberry Pancakes", price: 110 },
    { id: 10, name: "Ice Cream", desc: "Vanilla Ice Cream", price: 70 },
    { id: 11, name: "Coffee", desc: "Cappuccino", price: 60 },
    { id: 12, name: "Milkshake", desc: "Chocolate Milkshake", price: 130 },
    { id: 13, name: "Tacos", desc: "Fish Tacos", price: 140 },
  ]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Set the selected file in state
      setFile(selectedFile);

      // Generate a preview URL for the selected file
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        setPreviewURL(e.target.result);
      };
      fileReader.readAsDataURL(selectedFile);
    }
  };
  const toggleItemSelection = (itemId) => {
    const isSelected = selectedItems.includes(itemId);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };
  return (
    <div className="addIntegrationCon">
      {" "}
      <Grid
        md={10.5}
        container
        sx={{ display: "flex", alignItems: "flex-end" }}
      >
        <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="success"
            variant="outlined"
            size="small"
            startIcon={<SaveIcon />}
            sx={{ mr: 3 }}
          >
            Save
          </Button>
          <Button variant="outlined" size="small" startIcon={<CachedIcon />}>
            Refresh
          </Button>
        </Grid>
      </Grid>
      <Grid md={11} container>
        <Grid item md={5}>
          <TextField label="Name" size="small" fullWidth sx={{ mb: 2 }} />
          <TextField label="Description" size="small" fullWidth />

          <div>
            <p>Upload Background Image</p>
            <input id="file-upload" type="file" onChange={handleFileChange} />
          </div>
          {/* <Autocomplete
            options={playlistList}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Playlist"
                size="small"
                sx={{ mt: 3 }}
              />
            )}
          /> */}
          <div>
            {previewURL ? (
              <div>
                <div className="previewCon background-img">
                  <img src={previewURL} alt="Image Preview" />
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }} // Adjust the number of columns as needed
                  >
                    {selectedItems.map((itemId, index) => {
                      const selectedItem = itemList.find(
                        (item) => item.id === itemId
                      );
                      return (
                        <div
                          key={itemId}
                          className="item-con"
                          style={{
                            top: `${10 + index * 30}px`, // Adjust spacing as needed
                            left: "10px", // Adjust the left position as needed
                          }}
                        >
                          <p>{selectedItem?.name}</p>

                          <p className="price">&#8377;{selectedItem?.price}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="previewCon">
                <p>Preview</p>
              </div>
            )}
          </div>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={5} sx={{ mt: 3 }}>
          <div className="mui-table-con ">
            <Table className="table" stickyHeader>
              <TableHead stickyHeader>
                <TableRow>
                  <TableCell className="tableCell"></TableCell>
                  <TableCell className="tableCell">Item Name</TableCell>
                  <TableCell className="tableCell">Descriprion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className="tableCell">
                      <Checkbox
                        onChange={() => toggleItemSelection(item.id)}
                        checked={selectedItems.includes(item.id)}
                      />
                    </TableCell>
                    <TableCell className="tableCell">{item?.name}</TableCell>
                    <TableCell className="tableCell">{item?.desc}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddIntegration;
