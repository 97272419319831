import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../core/mui-table";
import { getHeaderConfig } from "./config";
import { Autocomplete, Button, Drawer, Grid, TextField } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddStores from "./addStores";
import CustomModal from "../../core/modal";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";

const Stores = () => {
  const [tableData, setTableData] = useState({
    totalPages: 1,
    results: [
      {
        storeId: "01",
        name: "Delhi_outlet_1",
        spec: "Mall_hor_3_ver_1",
        status: "active",
        upTime: "5 days",
        url: "https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg",
      },
      {
        storeId: "02",
        name: "Punjab_outlet_1",
        spec: "Res_hor_3_ver_1",
        status: "inactive",
        upTime: "-",
        url: "https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg",
      },
      {
        storeId: "03",
        name: "Delhi_outlet_2",
        spec: "Mall_hor_3_ver_1",
        status: "active",
        upTime: "5 days",
        url: "https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg",
      },
      {
        storeId: "04",
        name: "Kerala_outlet_1",
        spec: "Res_hor_3_ver_1",
        status: "active",
        upTime: "3 days",
        url: "https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg",
      },
      {
        storeId: "05",
        name: "Punjab_outlet_2",
        spec: "Mall_hor_3_ver_1",
        status: "active",
        upTime: "7 days",
        url: "https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg",
      },
      {
        storeId: "06",
        name: "Himachal_outlet_2",
        spec: "Res_hor_3_ver_1",
        status: "inactive",
        upTime: "1 days",
        url: "https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg",
      },
      {
        storeId: "07",
        name: "Noida_outlet_1",
        spec: "Mall_hor_3_ver_1",
        status: "active",
        upTime: "10 days",
        url: "https://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg",
      },
    ],
  });
  const [preView, setPreview] = useState({
    isOpen: false,
    data: {},
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [isAddStore, setIsAddStore] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [storeNameList, setStoreNameList] = useState([]);
  const [searchStore, setSearchStore] = useState("");
  const [isDelete, setIsDelete] = useState({
    isOpen: false,
    data: {},
  });
  const clickHandler = (data, type) => {
    switch (type) {
      case "edit":
        setIsAddStore(true);
        setEditObj(data);
        break;
      case "delete":
        setIsDelete({
          isOpen: true,
          data,
        });
        break;
      default:
        break;
    }
  };
  const getStores = (filter) => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.STORE}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTableData(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const deleteHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.STORE}/${isDelete.data.id}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Store Deleted Successfully", { autoClose: 2000 });
          setIsDelete({
            isOpen: false,
            data: {},
          });
          getStores(filters);
        }
      })
      .catch((err) => console.error(err));
  };
  const getStoreList = (value) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_STORE}/${value}`
      )
        .then((res) => {
          const result = res.map((ele) => ({
            label: ele.name,
            value: ele.name,
          }));
          setStoreNameList(result);
        })
        .catch((err) => console.error(err));
    }, 100);
  };
  const onInputChange = (event, value) => {
    if (value?.trim()) {
      getStoreList(value);
    } else {
      setSearchStore("");
    }
  };
  const searchHandler = () => {
    if (searchStore) {
      getStores({ ...filters, page: 1, name: searchStore });
    } else {
      getStores({ ...filters, page: 1 });
    }
  };
  useEffect(() => {
    getStores();
  }, []);
  return (
    <>
      {!isAddStore && (
        <div className="media-con">
          <h1 className="header">Stores</h1>
          <Grid container md={12} sx={{ mb: 2 }}>
            <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
              <Grid item md={3.5}>
                <Autocomplete
                  options={storeNameList}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Store" size="small" />
                  )}
                  onInputChange={onInputChange}
                  onChange={(e, val) => {
                    if (val?.value) {
                      setSearchStore(val.value);
                    } else {
                      setSearchStore("");
                    }
                  }}
                />
              </Grid>
              <Grid item md={3.5}>
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<SearchIcon />}
                  onClick={searchHandler}
                  sx={{ ml: 4 }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<AddOutlinedIcon />}
                onClick={() => setIsAddStore(true)}
              >
                Add Store
              </Button>
            </Grid>
          </Grid>

          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={tableData?.results || []}
            filters={filters}
            onClick={clickHandler}
            pageCount={tableData?.totalPages}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
      )}
      {isAddStore && (
        <AddStores
          setIsAddStore={setIsAddStore}
          getStores={getStores}
          editObj={editObj}
          setEditObj={setEditObj}
        />
      )}
      {preView?.isOpen && (
        <CustomModal onClose={() => setPreview({ isOpen: false, data: {} })}>
          <Grid
            md={12}
            container
            sx={{ display: "flex", alignItems: "center" }}
            spacing={10}
          >
            <Grid item md={3}>
              {" "}
              <img
                src="https://www.allrecipes.com/thmb/t0hwFCNsLEKcYmcg58jpWXsNTYk=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/subway-releases-new-sandwiches-4x3-1-23115e70956e4aff9692c7967e8a5333.png"
                className=" horizontal-img"
              />
            </Grid>
            <Grid item md={3}>
              {" "}
              <img
                src="https://www.hindustantimes.com/ht-img/img/2023/07/28/1600x900/Sandwich_1690533751197_1690533796470.webp"
                className="horizontal-img"
              />
            </Grid>
            <Grid
              item
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src="https://cloudfront-us-east-1.images.arcpublishing.com/gray/R2OUPRI2PBHR7BROTNOBMCHL6A.jpg"
                className="vertical-img"
              />
            </Grid>
            <Grid item md={3}>
              <img
                src="https://b.zmtcdn.com/data/menus/256/100256/1f505c0c515cd7f3ad3496166a224d8f.jpg"
                className="horizontal-img"
              />
            </Grid>
          </Grid>
        </CustomModal>
      )}
      {isDelete?.isOpen && (
        <CustomModal
          title="Delete Store"
          onClose={() => {
            setIsDelete({
              isOpen: false,
              data: {},
            });
          }}
        >
          <h1>Are You Sure?</h1>
          <Button variant="contained" color="error" onClick={deleteHandler}>
            Delete
          </Button>
        </CustomModal>
      )}
    </>
  );
};

export default Stores;
